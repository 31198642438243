import { useEffect, useState } from 'react'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Select from 'react-select';

const MarcasSelector = (props) => {

    const [ modelos, setModelos ] = useState([])
    const [ loadingModelos, setLoadingModelos ] = useState(true)
    const [ multiplesValores, setMultiplesValores ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const token = props.token ? props.token : false
    const default_value = props.default_value ? props.default_value : false
    const [ value, setValue ] = useState('')
    const isMulti = props.isMulti ? props.isMulti : false
    
    const obtenerModelos = async (page, query) => {
        setLoadingModelos(true)
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/marcas/selector`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingModelos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingModelos(false)
            } else if(Array.isArray(res) !== false){
                setModelos(res)
            }
            return setLoadingModelos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingModelos(false)
        })
    }

    const handleChangeSelectModelo = (id) => {
        if(isMulti === true){
            setMultiplesValores(id)
            if(props.handleSelectModeloMulti) {
                return props.handleSelectModeloMulti(id)
            }
        } else {
            if(props.handleSelectModelo) {
                const i = modelos.findIndex(m => m._id === id.value)
                if(i < 0) return false
                return props.handleSelectModelo(modelos[i])
            }
            setValue(id)
        }
    }

    useEffect(() => {
        obtenerModelos()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const options = modelos.map(m => ({ value: m._id, label: m.titulo }))

    let valor = false
    if(!isMulti){
        if(default_value){
            const i_valor = options.findIndex(o => o.value === default_value )
            if(i_valor > -1) valor = options[i_valor]
        }
    } else if (isMulti === true){
        valor = multiplesValores
    }
 
    return <div>
        <Select
            style={{ marginBottom: 10 }}
            onChange={handleChangeSelectModelo}
            options={options}
            value={valor}
            isLoading={loadingModelos}
            isMulti={isMulti}
            placeholder="Buscar marca..."
        />
    </div>

}

export default MarcasSelector