import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { userService } from '../../services/user.service';
import { Row, Col, Container, Form, Button, Card } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { validateEmail } from '../../lib/helpers/helpers';
import { toast } from 'react-toastify';
import guardarUsuario from '../../redux/actions/guardarUsuario';

const Login = ({ history }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorLogin, setErrorLogin] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch()

    const handleChangePass = (e) => {
        setShowPassword(e.target.checked);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'username') {
            setUsername(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleSubmit = () => {
        setSubmitted(true);
        if (!username) return toast.error("Ingresa el email");
        if (!password) return toast.error("Ingresa la contraseña");
        if (validateEmail(username) !== true) return toast.error('Email inválido');
        setLoading(true);
        userService.login(username, password)
            .then(
                user => {
                    if (!user) {
                        setErrorLogin("Email o Contraseña erroneas");
                        setLoading(false);
                    } else {
                        dispatch(guardarUsuario(user))
                        const { from } = history.location.state || { from: { pathname: "/" } };
                        history.push(from);
                    }
                },
                error => {
                    setErrorLogin(error);
                    setLoading(false);
                }
            );
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md={4} className="centerv">
                    <Card className='p-3'>
                        <h1>BUMER</h1>
                        <h3>Acceso privado</h3>
                        <Form.Group>
                            <Form.Control type="email" name="username" placeholder="Email" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control type={showPassword ? "text" : "password"} name="password" placeholder="Contraseña" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="switch" onChange={handleChangePass} label="Mostrar contraseña" />
                        </Form.Group>
                        <Form.Group>
                            {loading ? <Spinner animation="border" /> : <Button onClick={handleSubmit} block variant="primary">ENTRAR</Button>}
                            {errorLogin ? <p className="text-danger">{errorLogin}</p> : false}
                        </Form.Group>
                        <Form.Text className="text-muted">
                            Todos los derechos reservados, Copyright 2014
                        </Form.Text>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Login