import { url_images } from "../../../lib/global/data"
// import { estilo_last_mile } from "../../../lib/global/styles"

const LoadingWarehouse = (props) => {
    const {
        titulo 
    } = props
    const title = titulo ? titulo : "Cargando información..."
    return <div className="p-3 text-center">
    <img style={{ maxWidth: "100%", width: 200 }} src={`${url_images}/animations/warehouse.gif`} />
    <h4 style={{ fontWeight: "bold" }}>{title}</h4>
    </div>
}

export default LoadingWarehouse