const data = {
    products_view: "list"
}

function reducer( state = data, { type, payload } ){
    switch( type ){
        case 'guardarInterfaz': {
            return payload
        }
        default:
            return state
    }
}

export default reducer