import BodegasListado from "../../subComponents/bodegas/bodegas/list"
import Structure from "../Structure"

const Bodegas = (props) => {

    const render = () => {
        return <div>
            <BodegasListado />
        </div>
    }

    return <Structure component={render()} />
}

export default Bodegas