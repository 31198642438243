import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button, Table, Tabs, Tab } from 'react-bootstrap';
import Header from '../Header';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import data, { key_local_storage_user } from '../../lib/backend/data';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/es';
import { checkPermission } from '../../lib/helpers/session';

function UsuariosListado(props) {
    const {
        typeView
    } = props
  const [user, setUser] = useState(JSON.parse(localStorage.getItem(key_local_storage_user)));
  const [loadingUsuarios, setLoadingUsuarios] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const [parametroBusqueda, setParametroBusqueda] = useState('');
  const [loadingBusqueda, setLoadingBusqueda] = useState(false);
  const [filtroRol, setFiltroRol] = useState('');

  useEffect(() => {
    const permitir = checkPermission('usuarios', user);
    if (permitir !== true) window.location = '/';
    getUsuarios(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'parametro_busqueda':
        setParametroBusqueda(value);
        break;
      case 'filtro_rol':
        setFiltroRol(value);
        break;
      default:
        break;
    }
  };

  const buscar = async () => {
    if (!parametroBusqueda) return toast.error('El valor de búsqueda es requerido');
    if (parametroBusqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido');
    setLoadingBusqueda(true);

    try {
      const res = await fetch(`${data.urlapi}/usuarios/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer: ${user.tokenSession}`
        },
        body: JSON.stringify({ parametro_busqueda: parametroBusqueda })
      });
      
      if (res.status === 401) window.location = '/login';
      const result = await res.json();
      
      if (!result) {
        toast.error('Sin datos');
      } else if (result.errorMessage) {
        toast.error(result.errorMessage);
      } else if (result.length < 1) {
        toast.warn('No se encontraron resultados');
      } else {
        setUsuarios(result);
        setFiltroRol('');
      }
    } catch (error) {
      toast.error("Error al consultar la información, intente nuevamente");
    } finally {
      setLoadingBusqueda(false);
    }
  };

  const getUsuarios = async (cargarMas) => {
    const loading = cargarMas ? setLoadingMore : setLoadingUsuarios;
    loading(true);

    try {
      const res = await fetch(`${data.urlapi}/usuarios/list?skip=${usuarios.length}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer: ${user.tokenSession}`
        }
      });

      if (res.status === 401) window.location = '/login';
      const result = await res.json();
      
      if (!result) {
        toast.error('Sin datos');
      } else if (result.errorMessage) {
        toast.error(result.errorMessage);
      } else {
        const newData = cargarMas ? usuarios.concat(result.datos) : result.datos;
        setUsuarios(newData);
        setTotal(result.total);
      }
    } catch (error) {
      toast.error("Error al consultar la información, intente nuevamente");
    } finally {
      loading(false);
    }
  };

  const buscarUsuario = (e) => {
    e.preventDefault();
    buscar();
  };

  const sinRegistros = () => (
    <div className='p-5'>
      <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <h4>Aún no tienes usuarios</h4>
          <p>Puedes crear diversos usuarios y asignar permisos a cada uno de ellos</p>
          <Link to="/usuarios/nuevo"><Button size="sm" variant="primary">CREAR NUEVO</Button></Link>
        </Col>
      </Row>
    </div>
  );

  const seleccionar = (registro) => {
    if(props.onSelect) props.onSelect(registro)
  }

  const showByTypeView = (registro) => {
    switch (typeView) {
        case "function":
            return <div className='hover text-primary' onClick={() => seleccionar(registro)}>{registro.nombres}</div>
        default:
            return <Link to={`/usuarios/edit-${registro._id}`}>{registro.nombres}</Link>
    }
  }

  const mostrarUsuarios = () => {
    if (loadingUsuarios) return <Spinner animation='border' />;
    if (usuarios.length < 1) return sinRegistros();

    const filtradosRol = filtroRol ? usuarios.filter(user => user.role === filtroRol) : usuarios;

    return (
      <div>
        <form onSubmit={buscarUsuario}>
          <Row className='mb-3'>
            <Col className='mb-3'>
              <label className='form-control-label d-block'>Buscar</label>
              <input className='form-control' name="parametro_busqueda" value={parametroBusqueda} onChange={handleChange} />
            </Col>
            <Col className='mb-3'>
              <label className='form-control-label d-block'>Click para buscar</label>
              {
                loadingBusqueda ? <Spinner animation='border' /> : <Button   variant="outline-primary" type="submit">BUSCAR</Button>
              }
            </Col>
            <Col>
              <label className='form-control-label d-block'>Filtrar por rol</label>
              <select className='form-control' name="filtro_rol" value={filtroRol} onChange={handleChange} >
                <option value="">Seleccione</option>
                <option value="user">Usuario</option>
                <option value="admin">Administrador</option>
              </select>
            </Col>
          </Row>
        </form>

        <h5 className='mb-3'>Se muestran {filtradosRol.length} de {total} registros</h5>
            <div className='table-responsive'>
              <Table bordered className='table'>
                <thead>
                  <tr>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Email</th>
                    <th>Teléfono</th>
                    <th>Estado</th>
                    <th>Creación</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filtradosRol.map((usuario, i) => (
                      <tr key={`user-${usuario._id}`}>
                        <th>{showByTypeView(usuario)}</th>
                        <th>{usuario.apellidos}</th>
                        <th>{usuario.email}</th>
                        <th>{usuario.phone}</th>
                        <th>{usuario.status === "active" ? 'Activo' : 'Inactivo'}</th>
                        <th><Moment locale="es" fromNow>{usuario.createdAt}</Moment></th>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              {
                usuarios.length < total && (
                  <div>
                    {
                      loadingMore ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={() => getUsuarios(true)}>CARGAR MÁS</Button>
                    }
                  </div>
                )
              }
            </div>
      </div>
    );
  };

  return (
    <div className="">
      {mostrarUsuarios()}
    </div>
  );
}

export default UsuariosListado
