import { Card } from "react-bootstrap"
import Header from "../Header"

const Structure  = (props) => {
    const {
        component
    } = props

    return <div className="fluid">
            <Header />
            <div className="container-fluid">
        <div className="row">
        <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pt-3 pb-2">
        </div>
        {component}
        </main>
        </div>
        </div>
        </div>
}

export default Structure