import React, { Component, useEffect, useRef, useState} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, Navbar, Container, Row, Button, Col, Table, Spinner, Card } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import { key_local_storage_cart, key_local_storage_pedidos, key_local_storage_user, urlapi } from '../../lib/backend/data'
import { logotipo } from '../../lib/global/data'
import Drawer from 'react-modern-drawer'
import Select from 'react-select';
import { obtenerFechaZonaHorariaLocal } from '../../lib/helpers/dates'
import { toast } from 'react-toastify'
import { formatearRUTChileno, formatoMoneda, formatoRut, validarRut } from '../../lib/helpers/helpers'
import CrearEmpresa from '../../subComponents/empresas/crear_modal'
import SucursalesSelector from '../../subComponents/bodegas/selector'
import ConfiguracionSelector from '../../subComponents/bodegas/selector'
import { rutas } from '../../lib/rutas'
import { FaMobileScreen } from 'react-icons/fa6'

const token_temporal = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjoiMG11a2kvanFnOUN1Zjg4enNhZlVkZU1ORkNFa2xXbUV0TDN5V2YyNCs3aVMvdHdrVTFoMEVPWC9BSzM4amxqK1BSOE45eDYxaTRFc1RXVDFJcmxTV2dUbWU0L0IrN2ZLT2IxcTNUbGwrdGNHQU1DSnNGT3R4UTRVSnNsSFV2N2V4RU1kQlNWc2o2bm40MERmV2puK3ovQ0lKTmw1NnVSZE11aFdpZ0pDNFA1MmxhQ3BTR3BVSE85TUtFcHNHQ2hQNzh6SVZwa0h1aTVqRVVuVWpyZUxMcVBlVXFLVVdwTHJDTzcvWEIzNGNIUmpMNXRYeUtMS0xPRVpuTTRydDVjUFpxRWZaSzZ4NDdYS2xmS1JhamZlUnZnTElhakFrczVsT2hGRjBkZm5Nc2JtNGhCTVpGZnZUSllIWXB0RnRka3N5U2FYUXdsc0ZVU256L2xTMmpzVHRiSnByQzRsa2xTdWV2czlYZ05zYUh6S1liYz0ifQ.QTiCZMAVgj6khNBt2c98xeysoAfEq2cCkfHHsrhKuY8'

const Header = (props) => {
    let carrito = props.carrito ? props.carrito : []
    const user = JSON.parse(localStorage.getItem(key_local_storage_user))
    const [show_drawer, setShowDrawer] = useState(false)
    const [tipoDoc, setTipoDoc] = useState("39")
    const [clientes, setClientes] = useState([
      {value: 2, label: "Cliente genérico"}
    ])
    const [clienteEspecifico, setClienteEspecifico] = useState(false)
    const [loadingBuscarCliente, setLoadingBuscarCliente] = useState(false)
    const [listaClientes, setListaClientes] = useState([])
    const mensaje_emitiendo_default = "Emitiendo DTE"
    const [loadingClientes, setLoadingClientes] = useState(false)
    const [loadingMetodosPago, setLoadingMetodosPago] = useState(false)
    const [clienteSeleccionado, setClienteSeleccionado] = useState(clientes.length > 0 ? clientes[0].value : false)
    const [ clienteSeleccionadoDetalle, setClienteSeleccionadoDetalle ] = useState(false)
    const [descuentoSeleccionado, setDescuentoSeleccionado] = useState(false)
    const [metodosPago, setMetodosPago] = useState([])
    const [pagos, setPagos] = useState([])
    const [termId, setTermId] = useState(false)
    const [nombreCliente, setNombreCliente] = useState("")
    const [salesManId, setSalesManId] = useState(1)
    const [wareHouseId, setWareHouseId] = useState("")
    const [motivo, setMotivo] = useState("")
    const [branchId, setBranchId] = useState()
    const [autorizado, setAutorizado] = useState(false)
    const [allow_invoice_witouth_payment, setAllowInvoiceWitouthPayment] = useState(false)
    const [iva, setIva] = useState(19)
    const [facturando, setFacturando] = useState(false)
    const [ultimoDocGenerado, setUltimoDocGenerado] = useState(localStorage.getItem("ultimo_doc_generado") ? localStorage.getItem("ultimo_doc_generado") : false)
    const [ mensajeFacturando, setMensajeFacturando ] = useState(mensaje_emitiendo_default)
    const [rut, setRut] = useState("")
    const [ tokenLaudus, setTokenLaudus ] = useState('')
    
    const [printerIPAddress, setPrinterIPAddress] = useState("192.168.1.120");
    const [printerPort, setPrinterPort] = useState("9100");
    const [textToPrint, setTextToPrint] = useState("");
    const [connectionStatus, setConnectionStatus] = useState("");

    const ePosDevice = useRef();
    const printer = useRef();
    const STATUS_CONNECTED = "Connected";


    const getUsuario = async () => {
      let loading = 'loadingUsuario'
      return fetch(`${urlapi}/usuarios/cuenta`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${user.tokenSession}`
          }
      })
      .then(res => {
          return res.json()
      })
      .then(res => {
          if(!res){
          } else if(res.errorMessage){
          } else if(res._id){
            if(res.salesmanId) setSalesManId(res.salesmanId)
            if(res.warehouseId) setWareHouseId(res.warehouseId)
            const instancia_sesion = user
            instancia_sesion.data = res
              localStorage.setItem(key_local_storage_user, JSON.stringify(instancia_sesion))
          }
          return false
      })
      .catch(error => error)
  }

    const obtenerMetodosPago = async () => {
      return fetch(`${urlapi}/invoice/metodospago`,{
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        if(!res){
            // toast.error('Sin datos')
            return false
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
            return false
        } else if(Array.isArray(res) !== false){

          let nuevas = [
            { value: "", label: "Selecciona"},
          ]
          res.map(p => {
            nuevas.push({ value: p.termId, label: p.name })
          })
            setMetodosPago(nuevas)
            // if(nuevas.length > 1) setTermId(nuevas[2].value)
        }
        return setLoadingMetodosPago(false)
    })
    .catch(error => {
      console.log(error.message)
        toast.error("Error al consultar métodos de pago, intente nuevamente")
        return setLoadingMetodosPago(false)
    })
    }

    const verificarAutorizacionPago = async () => {
      const autorizar_pago = await obtenerCuenta()
      setAutorizado(autorizar_pago)
    }

    const validatePrinter = () => {
      let ePosDev = new window.epson.ePOSDevice();
      ePosDevice.current = ePosDev;

      ePosDev.connect(printerIPAddress, printerPort, (data) => {
        if (data === "OK") {
          ePosDev.createDevice(
            "local_printer",
            ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: true, buffer: false },
            (devobj, retcode) => {
              if (retcode === "OK") {
                printer.current = devobj;
                setConnectionStatus(STATUS_CONNECTED);
              } else {
                throw retcode;
              }
            }
          );
        } else {
          throw data;
        }
      });
    }

    useEffect(() => {
      getUsuario()
      window.onload = () => {
       // validatePrinter()
      };
        verificarAutorizacionPago()
        if(carrito.length < 1){
            if(localStorage.getItem(key_local_storage_cart)){
                const parseCart = JSON.parse(localStorage.getItem(key_local_storage_cart))
                carrito = parseCart
            }
        }
        obtenerMetodosPago()
    }, [])

    const toggleDrawer = (e) => {
        setShowDrawer(false)
    }

    const limpiarCarrito = () => {
      if(props.cleanCart){
        props.cleanCart()
      }
      setPagos(prev => [...[], ...[]])
    }

    const handleChangeCantidad = (e) => {
        const { name, value } = e.target
        const i = carrito.findIndex(p => p._id === name)
        if(i < 0) return false
        carrito[i].cantidad = value
        if(props.onChangeCarrito) props.onChangeCarrito(carrito)
        return false
    }

    const guardarUltimoDoc = (val) => {
      localStorage.setItem("ultimo_doc_generado", val)
      setUltimoDocGenerado(val)
    }

    const buscarPDF = async (invoiceId, tok) => {
      setMensajeFacturando("Generando PDF")
      return fetch(`https://api.laudus.cl/sales/invoices/${invoiceId}/pdf`,{
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${tok}`
        }
    })
    .then(res => {
        if(res.status === 401) {
          setFacturando(false)
          return toast.error("NO autorizado para consultar PDF")
        }
        return res.blob()
    })
    .then(blob => {

          if(blob){
            // Leer el archivo Blob como una cadena de datos
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              // Obtener la cadena de datos base64 del archivo
              const base64data = reader.result.split(',')[1];
              
              const pdfObject = document.createElement('object');
              pdfObject.data = 'data:application/pdf;base64,' + base64data;
              pdfObject.type = 'application/pdf';
              pdfObject.setAttribute('media', 'print');

              if(document.getElementById('pdf-container')){
                const pdfContainer = document.getElementById('pdf-container');
                pdfContainer.innerHTML = '';
                pdfContainer.appendChild(pdfObject);
                setTimeout(() => {
                  if(document.getElementById('secondaryPrint')){
                    document.getElementById('secondaryPrint').click()
                  }
                }, 4000);
              }
            };
            /*
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `${invoiceId}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            */
          }
          return setFacturando(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setFacturando(false)
    })
    }

    const handleChangeMotivo = (e) => {
      const { value } = e.target
      return setMotivo(value)
    }

    const campoMotivo = () => {
      if(tipoDoc.toString() !== "52") return false
      return <div className='mb-3'>
        <label className='form-control-label d-block'>Motivo</label>
        <input className='form-control' onChange={handleChangeMotivo} />
      </div>
    }

    const obtenerCuenta = async () => {
      return fetch(`${urlapi}/session/details`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${user.tokenSession}`
          }
      })
      .then(res => {
          if(res.status === 401) return window.location = '/login'
          return res.json()
      })
      .then(res => {
          if(!res){
            return false
          } else if(res.errorMessage){
            return false
          } else if(res._id){
            user.data = res
            localStorage.setItem(key_local_storage_user, JSON.stringify(user))
            return res.allow_invoice_witouth_payment === true ? true : false
          }
          return false
        })
      .catch(error => false)
  }

    const facturar = async () => {

      
      
      if(tipoDoc === "39") {
        // Boleta electrónica
        if(user.data.allow_emit_boleta !== true) return toast.error("Tipo de documento no permitido para tu usuario")

      } else if(tipoDoc === "33") {
        // Factura electrónica
        if(user.data.allow_emit_factura !== true) return toast.error("Tipo de documento no permitido para tu usuario")

      } else if(tipoDoc === "52") {
        // Guia de despacho electrónica
        if(user.data.allow_emit_guia_despacho !== true) return toast.error("Tipo de documento no permitido para tu usuario")

      } else if(tipoDoc === "1000") {
        // Pedido para picking
        if(user.data.allow_emit_guia_pedido !== true) return toast.error("Tipo de documento no permitido para tu usuario")

      } else if(tipoDoc === "1001") {
        // Cotización
        if(user.data.allow_emit_cotizacion !== true) return toast.error("Tipo de documento no permitido para tu usuario")

      } 
      
      
      let isGuiaDespacho = false
//        if(!termId) return toast.error("Selecciona un método de pago")
        if(!user.data.sucursalId) return toast.error("No tienes sucursal asignada a tu usuario")

        if(carrito.length < 1) return toast.error("Agrega al carrito al menos un producto")
        let ids_descuentos = []
        for( const pro of carrito ){
          if(!pro.descuentos) continue
          if(Array.isArray(pro.descuentos) !== true) continue
          if(pro.descuentos.length < 1) continue
          ids_descuentos = [...ids_descuentos, ...pro.descuentos]
        }

        setFacturando(true)

        if(ids_descuentos.length > 0){
          const { allow } = await validarDescuentos(ids_descuentos) 
          if(allow === false) {
            setFacturando(false)
            return toast.error("Descuentos aplicados no válidos")
          }
        }

        const fecha = obtenerFechaZonaHorariaLocal()
        if(tipoDoc.toString() === "52") isGuiaDespacho = true

        let motivo_guia = ''
        if(isGuiaDespacho) motivo_guia = motivo

        let items = []
        let calcular_total = carrito.reduce((prev, next) => {
          let cantidad = parseInt(next.cantidad)
          if(isNaN(cantidad)) cantidad = 0
          return prev + (next.precio * cantidad)
        }, 0)

        if(calcular_total < 1) {
          setFacturando(false)
          return toast.error("Monto de venta inválido")
        }

        for( const pro of carrito ){
          if(!pro.id) {
            setFacturando(false)
            return toast.error(`${pro.titulo} no está sincronizado, no posee ID`)
          }

          const sub_total = pro.cantidad * pro.precio

          const precio_neto = (pro.precio / 1.19).toFixed(2)
          const iva = Math.round((pro.precio - precio_neto) * pro.cantidad)

          const payload_producto = {
            "product": {
              "productId": pro.id,
              "sku": pro.codigo,
              "description": pro.titulo
            },
            "quantity": pro.cantidad,
            "originalUnitPrice": precio_neto,
            "unitPrice": precio_neto,
            "VAT": iva,
            "VATRate": 19.0
          }
          items.push(payload_producto)
        }
        
        let payload = {
            "docType": {
              "docTypeId": parseInt(tipoDoc),
            },
            "customer": {
              "customerId": clienteSeleccionado
            },
            "warehouse": {
              "warehouseId": wareHouseId
            },
            "salesman": {
              "salesmanId": salesManId,
            },
            "branch": {
              "branchId": user.data.sucursalId
            },
            "SII_transactionType": "1",
            "nullDoc": false,
            "issuedDate": fecha,
            "dueDate": fecha,
            "payInOriginalCurrency": true,
            "purchaseOrderNumber": "",
            "deliveryCost": 0,
            "deliveryNotes": motivo_guia,
            "deliveryVehiclePlate": "",
            "bypassCreditLimit": true,
            "notes": "",
            "createAccounting": true,
            "items": items,
            pagos
          }

          if(["39","33","52"].includes(tipoDoc)){
            if(pagos.length < 1) {

                if(allow_invoice_witouth_payment !== true) {
                  setFacturando(false)
                  return toast.error("No agregaste ningún método de pago, agrega al menos un tipo de pago")
                }
                const autorizar_pago = await obtenerCuenta()
                if(autorizar_pago !== true) {
                  setFacturando(false)
                  return toast.error("Operación no autorizada, recarga la página para refrescar la información")
                }
                
              } else {
                const faltantes = pagos.filter(p => typeof p.tipo === "undefined" || typeof p.monto === "undefined" || !p.tipo ).length
                if(faltantes > 0) {
                  setFacturando(false)
                  return toast.error("Debes configurar todos los pagos")
                }

                const invalidos = pagos.filter(p => {
                  if(isNaN(parseInt(p.monto))) return true
                }).length
                if(invalidos > 0) {
                  setFacturando(false)
                  return toast.error("Debes configurar el monto de todos los pagos")
                }

                const total_sumado = pagos.reduce((prev,next) => prev + next.monto, 0)
                if(total_sumado !== calcular_total ) {
                  setFacturando(false)
                  return toast.error("Monto de pagos no coincide con el monto de venta")  
                }

              }
            }

            // SETEAR FORMA DE PAGO

            if(tipoDoc.toString() === "39"){
              
              payload.term = {
                termId: "05",
                name: "SII Contado"
              }

            } else if(tipoDoc.toString() === "33"){
              
              if(pagos.length > 0){

                payload.term = {
                  termId: "05",
                  name: "SII Contado"
                }

              } else {
                payload.term = {
                  termId: "06",
                  name: "SII Credito"
                }
              }
            }

            let precio_sin_iva = false

            if(["1001","1000"].includes(tipoDoc)){
              // VALIDACIONES DE COTIZACIONES
              if(!clienteSeleccionadoDetalle) {
                setFacturando(false)
                return toast.error("Selecciona un cliente")
              }
              precio_sin_iva = true
            }
            // VALIDACIONES DE FACTURA, NO PUEDE IR SIN CLIENTE
            if(["33"].includes(tipoDoc)) if(!clienteSeleccionadoDetalle) {
              setFacturando(false)
              return toast.error("Selecciona un cliente")
            }

            if(isGuiaDespacho === true){
            payload.deliverySIIType = "1"
            payload.isSale = true
            payload.term = {
                  termId: "05",
                  name: "SII Contado"
            }
            delete payload.SII_transactionType
          }

          const enviar = {
            carrito,
            cliente: clienteSeleccionadoDetalle,
            payload,
            user: user.data
          }


          setMensajeFacturando(mensaje_emitiendo_default)
          return fetch(`${urlapi}/invoice`,{
            method:'POST',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setFacturando(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setFacturando(false)
            } else if(res.salesInvoiceId){
                toast.success(`Emitido el documento ${res.salesInvoiceId}`)
                guardarUltimoDoc(res.salesInvoiceId)
                limpiarCarrito()
                setTokenLaudus(res.token)
                return buscarPDF(res.salesInvoiceId, res.token)
            } else if(res.salesWaybillId){
                toast.success(`Emitido el documento ${res.salesWaybillId}`)
                guardarUltimoDoc(res.salesWaybillId)
            } else if(res._id){
              if(payload.docType.docTypeId === 1000){
                toast.success(`Emitido el pedido ${res.id}`)
              } else if(payload.docType.docTypeId === 1001){
                toast.success(`Emitida la cotización ${res.id}`)
              } else {
                toast.success(`Emitido el registro #${res.id}`)
              }
                guardarUltimoDoc(res.id)
              }
              limpiarCarrito()
            return setFacturando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setFacturando(false)
        })
    }

    const removerCarrito = (pos) => {
        if(props.onProductRemoved){
            props.onProductRemoved(pos)
        }
        return false
    }

    const validarDescuentos = async (ids_descuentos) => {
      const filtrar = ids_descuentos.filter(de => de)
      return fetch(`${urlapi}/descuentos/validar`,{
        method:'POST',
        body: JSON.stringify({
          ids_descuentos: filtrar
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos de validez de descuento')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(typeof res.allow !== "undefined"){
          return { allow: res.allow }
        }
        return { allow: false}
    })
    .catch(error => {
        return { allow: false }
    })
    }

    const buscarCliente = () => {
      const dato = formatoRut(rut)
      setLoadingBuscarCliente(true)
      setClienteSeleccionado(false)
      setClienteSeleccionadoDetalle(false)
      return fetch(`${urlapi}/clients/list`,{
        method:'POST',
        body: JSON.stringify({
          rut: dato
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(Array.isArray(res) !== false){
            if(res.length < 1) toast.warn("Sin resultados encontrados")
            setListaClientes(res)
        }
        return setLoadingBuscarCliente(false)
    })
    .catch(error => {
        toast.error("Error al consultar métodos de pago, intente nuevamente")
        return setLoadingBuscarCliente(false)
    })
    }

    const handleChangeTerm = (e) => {
      const { value } = e.target
      return setTermId(value)
    }
    
    const handleChangeRut = (e) => {
      const { value } = e.target
      const valor_formateado = formatearRUTChileno(value)
      if(document.getElementById('rut_chileno')) document.getElementById('rut_chileno').value = valor_formateado
      return setRut(valor_formateado)
    }
    
    const handleChangeSalesMan = (e) => {
      const { value } = e.target
      return setSalesManId(value)
    }

    const seleccionarCliente = (client) => {
        setClienteSeleccionado(client.customerId)
        setClienteSeleccionadoDetalle(client)
        setDescuentoSeleccionado(false)
    }

    const handleChangeNombreCliente = (e) => {
      const { value } = e.target
      return setNombreCliente(value)
    }

    const buscarClienteMongobuscarClienteMongo = async () => {
      setLoadingBuscarCliente(true)
      setClienteSeleccionado(false)
      setClienteSeleccionadoDetalle(false)
      return fetch(`${urlapi}/clients/list-advance`,{
        method:'POST',
        body: JSON.stringify({
          condicion: { $text: { $search: nombreCliente } }
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${user.tokenSession}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(Array.isArray(res.datos) !== false){
            if(res.datos.length < 1) toast.warn("Sin resultados encontrados")
            setListaClientes(res.datos)
        }
        return setLoadingBuscarCliente(false)
    })
    .catch(error => {
        toast.error("Error al consultar métodos de pago, intente nuevamente")
        return setLoadingBuscarCliente(false)
    })
    }


    const mostrarFormularioCliente = () => {
      if(!clienteEspecifico) return false
      return <Row>
          <Col md={3} xs={3}>
          <input className='form-control' id="rut_chileno" placeholder='RUT. Ejemplo: 25691135-3'  onKeyUp={handleChangeRut} />
          </Col>
          <Col md={3} xs={3}>
              {
                loadingBuscarCliente ? <Spinner animation='border' /> : <Button variant="success" className='mb-1' onClick={() => buscarCliente()} >BUSCAR CLIENTE</Button>
              }
              <CrearEmpresa onCreatedNewCliente={(data) => {
                setListaClientes([data])
                setClienteSeleccionado(data.customerId)
                setClienteSeleccionadoDetalle(data)
              }} />
          </Col>
          <Col className='mb-3' xs={3}>
          <input className='form-control' id="rut_chileno" placeholder='Nombre o Razón social'  onKeyUp={handleChangeNombreCliente} />
          </Col>
          <Col className='mb-3' xs={3}>
              <Button className='w-100' disabled={loadingBuscarCliente} onClick={() => buscarClienteMongobuscarClienteMongo()} >BUSCAR NOMBRE</Button>
          </Col>
          <Col xs={12}>
          {
            listaClientes.length > 0 ? <div>
              <Table className='w-100'>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Rut</th>
                  </tr>
                </thead>
                <tbody>
                {
                  listaClientes.map(cliente => {
                    return <tr key={`cliente-${cliente.customerId}`} className="hover" onClick={() => seleccionarCliente(cliente)}>
                      <th>
                        <p className='mb-0'>{clienteSeleccionado===cliente.customerId ? <i className="text-success fas fa-check-square"></i> : <i className="far fa-square"></i>} {cliente.name}</p>
                      </th>
                      <th><p className='mb-0'>{cliente.vatId}</p></th>
                    </tr>
                  })
                }
                </tbody>
              </Table>
            </div> : <div>
              {
                rut ? <div>
                  <Button variant="success">CREAR NUEVO</Button>
                </div> : false
              }
            </div>
          }
          </Col>
        </Row>

      return false
    }
    
    const handleChangeWareHouse = (value) => {
      return setWareHouseId(value)
    }

    const switchClienteEspecifico = () => {
      const valor = clienteEspecifico === true ? false : true
      setClienteEspecifico(valor)
    }
    
    const handleChangeBranchId = (e) => {
      const { value } = e.target
      return setBranchId(value)
    }

    const handleChangeTipoDoc = (e) => {
      const { value } = e.target
      return setTipoDoc(value)
    }

    const agregarPago = () => {
      if(allow_invoice_witouth_payment === true) return toast.error("Seleccionaste EMITIR SIN PAGO, desactivalo para poder añadir pagos")
      pagos.unshift({ tipo: "", monto: 0 })
      return setPagos(prev => [...[], ...pagos])
    }

    const maximo = (pos, monto_actual) => {
      let calcular_total = carrito.reduce((prev, next) => {
            let cantidad = parseInt(next.cantidad)
            if(isNaN(cantidad)) cantidad = 0
            return prev + (next.precio * cantidad)
        }, 0)

        let total_sumado = pagos.reduce((prev,next) => {
          let monto_pago = next.monto
          if(isNaN(parseInt(monto_pago))) monto_pago = 0
          return prev + monto_pago
        }, 0)

        let total_monto_actual = 0
        if(!isNaN(parseInt(monto_actual))) total_monto_actual = parseInt(monto_actual)
//        total_sumado = total_sumado + total_monto_actual

        if(total_sumado > calcular_total){
          return toast.error("El total sumado de los pagos es mayor al total del carrito")
        } else if (total_sumado === calcular_total){
          return false
        }

        const restante = calcular_total - total_sumado + total_monto_actual
        pagos[pos].monto = restante

        return setPagos(prev => [...[], ...pagos])

    }

    const handleChangeAmount = (e) => {
      const { value } = e.target
      const pos = parseInt(e.target.getAttribute('pos'))
      pagos[pos].monto = parseInt(value)
      return setPagos(prev => [...[], ...pagos])
    }

    const handleChangePago = (e) => {
      const { value } = e.target
      const pos = parseInt(e.target.getAttribute('pos'))
      pagos[pos].tipo = value
      return setPagos(prev => [...[], ...pagos])
    }

    const eliminar = (pos) => {
      pagos.splice(pos, 1)
      return setPagos(prev => [...[], ...pagos])
    }

    const accionesMetodoPago = () => {
      if(!autorizado) return false
      return <Button className='ml-3 w-100' size="sm" variant="outline-primary" onClick={() => {
          setPagos(prev => [...[], ...[]])
          const nuevo_valor = allow_invoice_witouth_payment === true ? false : true
          return setAllowInvoiceWitouthPayment(nuevo_valor)
        }}>{allow_invoice_witouth_payment === true ? <i className="text-success fas fa-check-circle"></i> : false } EMITIR SIN PAGO</Button>
    }

    const mostrarEstadoAutorizacionPago = () => {
      if(autorizado === true) return <div className='mt-3'>
        <Card bg='warning' className='p-2'>
          <h6 className='mb-0'><i className="fas fa-exclamation-triangle"></i> ESTÁS AUTORIZADO PARA EMITIR DOCUMENTOS SIN PAGOS</h6>
        </Card>
      </div>
    }

    const mostrarPagos = () => {

      return <div>
        {/* <Row>
          <Col md={4}><Button size="sm" variant='outline-primary' className='w-100' onClick={() => agregarPago()}>AGREGAR DO DE PAGO</Button></Col>
          <Col md={4}>{accionesMetodoPago()}</Col>
        </Row>
        
        {mostrarEstadoAutorizacionPago()}
        {
          pagos.map((pago,ip) => {
            return <div key={`e-${ip+1}`}>
              <Row>
                <Col >
                  <label className='form-control-label'> Tipo de pago </label>
                  <select className='form-control' value={pago.tipo} pos={ip} onChange={handleChangePago}>
                    <option value="">Seleccione</option>
                    <option value="E">Efectivo</option>
                    <option value="I">Depósito en C/C - Transferencia</option>
                    <option value="T">Tarjeta de crédito</option>
                    <option value="D">Tarjeta de débito</option>
                  </select>
                  <h6 className='mb-2 mt-2 hover text-danger' onClick={() => eliminar(ip)}><i className="fas  fa-trash"></i> Remover</h6>
                </Col>
                <Col>
                <label className='form-control-label'>Monto</label>
                <input className='form-control' pos={ip} value={pago.monto} type="number" onChange={handleChangeAmount} />
                <p className='mb-0 hover' style={{ position: "absolute", right: "30px", marginTop: -32 }} onClick={() => maximo(ip, pago.monto)}><b>MAX</b></p>
                </Col>
              </Row>
            </div>
          })
        } */}
      </div>
    }

    const mostrarMetodosPago = () => {
      return mostrarPagos()
    }

    const removerProductosDeCotizacion = (id) => {

      const filtrar = carrito.filter(pro => {
        if(!pro.cotizacion) return true
        if(pro.cotizacion === id) return false
        return true
      })
      if(props.onChangeCarrito) props.onChangeCarrito(filtrar)
      return 
    }

    const removerProductosDePedido = (id) => {

      const filtrar = carrito.filter(pro => {
        if(!pro.pedido) return true
        if(pro.pedido === id) return false
        return true
      })
      if(props.onChangeCarrito) props.onChangeCarrito(filtrar)
      return 
    }

    const alertaDescuentos = producto => {
      if(!producto.descuentos) return false
      if(Array.isArray(producto.descuentos) !== true) return false
      if(producto.descuentos.length < 1) return false
      return <Card bg="secondary" className='p-1 text-center'><h6 style={{ fontSize: 11, color: "white" }} className='mb-0'>DESCUENTO APLICADO</h6></Card>
    }

    const mostrarCotizacionesAsociadas = () => {

      let cotizaciones_asociadas = []
      for(const pro of carrito){
        if(!pro.cotizacion) continue
        if(!cotizaciones_asociadas.includes(pro.cotizacion)) cotizaciones_asociadas.push(pro.cotizacion)
      }

      return <div>
        { cotizaciones_asociadas.length > 0 ? <Card className='p-2 mb-3'>
                        <h6 className='mb-2'><i className="fas fa-shopping-basket"></i> COTIZACIONES ASOCIADAS</h6>
                        {
                          cotizaciones_asociadas.map((pedido,i) => {
                            return <div key={`ped-${i}`}>
                              <Table className='mb-0'>
                                <thead>
                                  <tr>
                                    <th>COTIZACIÓN</th>
                                    <th>ACCION</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th>{pedido}</th>
                                    <th><Button size="sm" variant="danger" className='mb-0' onClick={() => removerProductosDeCotizacion(pedido)}>REMOVER PRODUCTOS DE ESTA COTIZACIÓN</Button></th>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          })
                        }
            </Card> : false 
        }
      </div>
    }

    const mostrarPedidosAsociados = () => {

      let pedidos_asociados = []
      for(const pro of carrito){
        if(!pro.pedido) continue
        if(!pedidos_asociados.includes(pro.pedido)) pedidos_asociados.push(pro.pedido)
      }
      
      return <div>
        { pedidos_asociados.length > 0 ? <Card className='p-2 mb-3'>
                        <h6 className='mb-2'><i className="fas fa-shopping-basket"></i> PEDIDOS ASOCIADOS</h6>
                        {
                          pedidos_asociados.map((pedido,i) => {
                            return <div key={`ped-${i}`}>
                              <Table className='mb-0'>
                                <thead>
                                  <tr>
                                    <th>PEDIDO</th>
                                    <th>ACCION</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th>{pedido}</th>
                                    <th><Button size="sm" variant="danger" className='mb-0' onClick={() => removerProductosDePedido(pedido)}>REMOVER PRODUCTOS DE ESTE PEDIDO</Button></th>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          })
                        }
            </Card> : false 
        }
      </div>
    }

    const mostrarCarrito = () => {

        let calcular_total = carrito.reduce((prev, next) => {
            let cantidad = parseInt(next.cantidad)
            if(isNaN(cantidad)) cantidad = 0
            return prev + (next.precio * cantidad)
        }, 0)

        return <div >
          { typeof ultimoDocGenerado !== "undefined" ? <Card className="p-3 mb-3 text-light" bg="dark"><h6 className='mb-0'>Último documento generado {ultimoDocGenerado} <Button onClick={() => buscarPDF(ultimoDocGenerado, tokenLaudus)} >IMPRIMIR</Button> </h6></Card> : false }
            <Row className='mb-3'>
                <Col xs={12}>
                <Row>
                    { /* <Col className='mb-3'>
                        <label className='form-control-label'>Sucursal</label>
                        <select className='form-control' onChange={handleChangeBranchId}>
                            <option value={1}>10 de Julio</option>
                            <option value={2}>San Isidro</option>
                        </select>
      </Col> */ }
                    <Col md={3}>
                      <ConfiguracionSelector disable={user.data.allow_change_warehouse === true ? false : true} url_refresh={`${urlapi}/configuracion/sincronizar-bodegas`} tipo="bodega" subtipo="selector"  default_value={user.data.warehouseId} onChange={(e) => handleChangeWareHouse(e)} titulo="Bodega" />
                    </Col>
                    <Col md={3} >
                        <label className='form-control-label'>Vendedor</label>
                        <select className='form-control' onChange={handleChangeSalesMan}>
                            <option value={1}>abdias</option>
                        </select>
                    </Col>
                    <Col md={3} xs={12}>
                        <label className='form-control-label'>Tipo de documento</label>
                        <select className='form-control' onChange={handleChangeTipoDoc} value={tipoDoc}>
                            <option value={39}>Boleta electrónica</option>
                            <option value={33}>Factura electrónica</option>
                            <option value={52}>Guia de despacho electrónica</option>
                            <option value={1000}>Pedido para picking</option>
                            <option value={1001}>Cotización</option>
                        </select>
                    </Col>
                    <Col md={3} xs={12}>
                        {mostrarMetodosPago()}
                    </Col>
                    <Col className='mb-0' xs={12}>
                        <label className='form-control-label'>Cliente <b className='text-primary hover' onClick={() => switchClienteEspecifico()} >{clienteEspecifico === true ? "Seleccionar cliente genérico" : "Seleccionar cliente específico"}</b></label>
                    </Col>
                    <Col md={12}>{campoMotivo()}</Col>
                    <Col md={12}>
                      {mostrarFormularioCliente()}
                    </Col>
                    
                </Row>
                </Col>
                <Col xs={12}>
                    <div>
                      {mostrarPedidosAsociados()}
                      {mostrarCotizacionesAsociadas()}
                    </div>
                </Col>
                <Col md={12}>
                    { facturando ? <div><Spinner animation='border' /><h5>{mensajeFacturando}</h5></div> : <Button style={{ width: '100%', height: 70, fontSize: 25 }} onClick={() => facturar()} >EMITIR DTE ${formatoMoneda(calcular_total)}</Button> }
                    <div id="pdf-container" style={{ width: "100%" }}></div>
                </Col>
            </Row>
                        <Table bordered>
                            <thead>
                                <tr>
                                <th>Código</th>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Precio Un.</th>
                                <th>Sub total</th>
                                <th></th>
                                </tr>
                            </thead>
                        {
                            carrito.map((pro,pos) => {

                                const cantidad = isNaN(parseInt(pro.cantidad)) ? 0 : parseInt(pro.cantidad)

                                return <tr key={pro._id}>
                                        <td>
                                          <h6>{pro.codigo}</h6>
                                          {pro.pedido ? <Card bg="warning" className='p-1'><h6 className='p-0 m-0' style={{ fontSize:11 }}>ASOCIADO AL PEDIDO {pro.pedido}</h6></Card> : false}
                                          {pro.cotizacion ? <Card bg="warning" className='p-1'><h6 className='p-0 m-0' style={{ fontSize:11 }}>ASOCIADO A COTIZACIÓN {pro.cotizacion}</h6></Card> : false}
                                        </td>
                                        <td>{pro.titulo}
                                        {alertaDescuentos(pro)}
                                        </td>
                                        <td><input className='form-control' value={pro.cantidad} type="number" name={pro._id} onChange={handleChangeCantidad} /></td>
                                        <td><h5>${formatoMoneda(pro.precio)}</h5></td>
                                        <td><h5>${formatoMoneda(cantidad * pro.precio)}</h5></td>
                                        <td><h5 className='hover text-danger' onClick={() => removerCarrito(pos)}><i className="fas fa-trash"></i></h5></td>
                                    </tr>
                            })
                        }
                        </Table>
        </div>
    }

    const checkPermission = (name) => {
      if(!user) return false
      if(!user.data) return false
      if(user.data.role === "admin") return true
      if(!user.data.permisos) return false
      if(Array.isArray(user.data.permisos) !== true) return false
      if(user.data.permisos.length < 1) return false

      const i = user.data.permisos.findIndex(permiso => permiso.module === name)
      if(i < 0) return false
      return true
    }

    const style = `nav-link`

    return <div>
        <Navbar  expand="lg" className='shadow ' >
            <Container fluid>
                <Navbar.Brand className='mr-3'><FaMobileScreen style={{ verticalAlign: "middle" }} />WEPOS 1.2</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                { checkPermission('facturacion') === true ? <Link to="/" className={style}>FACTURACIÓN</Link> : false }
                { checkPermission('usuarios') === true ? <Link to="/usuarios" className={style}>USUARIOS</Link>  : false }
                {/* { checkPermission('cilindrada') === true ? <Link to="/cilindradas" className={style}>CILINDRADAS</Link>  : false } */}
                {/* { checkPermission('marcas') === true ? <Link to="/marcas" className={style}>MARCAS</Link>  : false } */}
                {/* { checkPermission('modelos') === true ? <Link to="/modelos" className={style}>MODELOS</Link>  : false } */}
                { checkPermission('productos') === true ? <Link to="/productos" className={style}>PRODUCTOS</Link>  : false }
                { checkPermission('cotizaciones') === true ? <Link to="/cotizaciones" className={style}>COTIZACIONES</Link>  : false }
                { checkPermission('pedidos') === true ? <Link to="/pedidos" className={style}>PEDIDOS</Link>  : false }
                { checkPermission('expediciones') === true ? <Link to={rutas.expediciones.slug} className={style}>EXPEDICIONES</Link>  : false }
                { checkPermission('bodegas') === true ? <Link to={rutas.bodegas.slug} className={style}>BODEGAS</Link>  : false }
                </Nav>  
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <h4 className='mb-0 hover'></h4>
                </Nav>
                    <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                    <i className="fas fa-user-circle"></i> {user.data.nombres}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="1"><Link to="/account" className="nav-link">Mi cuenta</Link></Dropdown.Item>
                        <Dropdown.Item eventKey="1"><Link to="/login" className="text-danger"><i class="fas fa-power-off"></i> Salir</Link></Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
        <Drawer
        open={show_drawer}
        onClose={()=>toggleDrawer()}
        direction='right'
        size={"70%"}
        className='drawer-carrito'
        >
        <div className='p-3' style={{ maxHeight: "100%", overflow: "scroll"}}>
           <h3>{carrito.length} productos</h3>
            {mostrarCarrito()}
        </div>
    </Drawer>
        </div>
}

export default Header