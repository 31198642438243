const rutas = {
    importador_productos:{
        slug: "/importador-productos"
    },
    expediciones:{
        slug: "/expediciones"
    },
    bodegas:{
        slug: "/bodegas"
    }
}

module.exports = {
    rutas
}