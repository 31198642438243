import { Spinner } from "react-bootstrap"
import S3FileUpload from 'react-s3';
const { useState } = require("react")
const { toast } = require("react-toastify")
const { configs3 } = require("../../lib/backend/data")

const ImageInput = () => {

    const [ loading, setLoading ] = useState(false)

    const handleChangeImagen = (e) => {
        if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
  
        setLoading(true)
        return S3FileUpload.uploadFile(archivo, {
            dirName: configs3.dirName,
            bucketName: configs3.bucketName,
            region: configs3.region,
            accessKeyId: configs3.accessKeyId,
            secretAccessKey: configs3.secretAccessKey,
        })
        .then(data => {
            console.log(data)
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            setLoading(false)
        }
        

        })
        .catch(err => {
            console.log(err.message)
            setLoading(false)
            return toast.error('No pudimos cargar tu imagen')
        })
    }
    return <div>
        { loading ? <Spinner animation="border" /> : false }
        <label className="form-control-label">Imagen</label>
        <input  
                    type="file" 
                    name="file" 
                    id="file" 
                    className="form-control"
                    onChange={handleChangeImagen} 
                    placeholder="Archivo de imagen" 
                    />

    </div>
}

export default ImageInput