import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Breadcrumb, Accordion, ButtonGroup } from 'react-bootstrap'
import data, { key_local_storage_user, urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import Select from 'react-select';
import { addDays } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import ModelosBuscador from '../../subComponents/modelos/buscador';
import CilindradaBuscador from '../../subComponents/cilindradas/buscador';
import { Link } from 'react-router-dom';
import ModelosSelector from '../../subComponents/modelos/selector';
import CilindradaSelector from '../../subComponents/cilindradas/selector';
import Inventario from '../../subComponents/repuestos/inventario';
import TraspasoAplicaciones from './traspaso_aplicaciones';
import Etiqueta from '../../subComponents/productos/etiqueta';
import MarcasSelector from '../../subComponents/marcas/selector';
import Structure from '../Structure';
import { useParams } from 'react-router-dom'
import { rutas } from '../../lib/rutas';
import { CiBarcode } from 'react-icons/ci';
import { LuBox } from "react-icons/lu";
import { FaAngleDown, FaCheckCircle, FaRegFileAlt } from 'react-icons/fa';
import { fechaATexto } from '../../lib/helpers/helpers';
import { IoAlertCircleSharp } from 'react-icons/io5';
import ModalSeleccionUsuarios from '../../subComponents/usuarios/modal_seleccion';
import ModalSeleccionBodega from '../../subComponents/bodegas/modal_seleccion';
import ModalSeleccionProductos from '../../subComponents/productos/modal_seleccion';

const EditarExpedicion = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ aplicaciones, setAplicaciones ] = useState([])
    const [ loadingBusqueda, setLoadingBusqueda ] = useState(false)
    const [ conductores, setConductores ] = useState([])
    const [ disableInputs, setDisableInputs ] = useState(false)
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ productos, setProductos ] = useState([])
    const [ loadingClonado, setLoadingClonado ] = useState(false)
    const [ guardando, setGuardando ] = useState(false)
    const [ loadingEliminar, setLoadingEliminar ] = useState(false)
    const [ loadingRuta, setLoadingRuta ] = useState(true)
    const [ conductor, setConductor ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ modelosMultiples, setModelosMultiple ] = useState([])
    const [ descripcion, setDescripcion ] = useState('')
    const [ showButtonUpdateDate, setShowButtonUpdateDate ] = useState(false)
    const [ loadingAplicacionId, setAplicacionId ] = useState(false)
    const [ loadingCopiado, setLoadingCopiado ] = useState(false)
    const session = JSON.parse(localStorage.getItem(key_local_storage_user))
    const token = session.tokenSession
    const route = useParams()
    const idproducto = route.id

    useEffect(() => {
        obtenerProducto(idproducto)
    },[])

    const obtenerProducto = async (id) => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/expediciones/detail?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                if(res.confirmed === true){
                    setDisableInputs(true)
                    toast.info("Esta expedición ya ha sido confirmada y no se puede modificar", { autoClose: 10000 })
                }
                setRuta(res)
                if(res.productos) setProductos(res.productos)
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const clonar = async (tipo) => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/expediciones/clone?id=${ruta._id}&tipo=${tipo}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                return window.location.href = `/expediciones-edit-${res._id}`
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }
    
    const eliminarProductoExpedicion = async (id) => {
        setLoadingEliminar(true)
        return fetch(`${data.urlapi}/expediciones/productos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setProductos(prev => {
                    let actual = [...prev]
                    const i = actual.findIndex(p => p._id === id)
                    actual.splice(i,1)
                    return actual
                
                })
            }
            return setLoadingEliminar(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingEliminar(false)
        })
    }


    const actualizarProducto = async (pro) => {
        return fetch(`${data.urlapi}/expediciones/productos`,{
            method:'POST',
            body: JSON.stringify({
                producto: pro,
                id_expedicion: ruta._id
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                return res
            }
            return false
        })
        .catch(error => false)
    }
    
    const actualizarProductoExpedicion = async (pro) => {
        return fetch(`${data.urlapi}/expediciones/productos`,{
            method:'PUT',
            body: JSON.stringify({
                producto: pro
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                return res
            }
            return false
        })
        .catch(error => toast.error('Error al guardar'))
    }

    const handleChangeCodigo = (e) => {
        const { value } = e.target
        return consultarProducto(value)
    }

    const handleChangeProducto = (e, i) => {
        const { name, value } = e.target
        return setProductos(prev => {
            let actual = [...prev]
            actual[i][name] = value
            actualizarProductoExpedicion(actual[i])
            return actual
        })
    }

    const cambiarRangoFecha = (item) => {
        setShowButtonUpdateDate(true)
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const confirmarCambioFecha = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/rutas/fecha`,{
            method:'PUT',
            body: JSON.stringify({
                id: ruta._id,
                fecha_min_entrega: selectionRange.startDate,
                fecha_max_entrega: selectionRange.endDate,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const limpiarInput = () => {
        setTimeout(() => {
            if(document.getElementById('codigobusqueda')){
                document.getElementById('codigobusqueda').value = ''
                document.getElementById('codigobusqueda').focus()
            }
        }, 200);
    }

    const agregarProducto = async (pro) => {
        const i = productos.findIndex(p => p.codigo === pro.codigo)

        if(i > -1){

            setProductos(prev => {
                let actual = [...prev]
                actual[i].cantidad = actual[i].cantidad + 1
                return actual
            })

        } else {
            let producto_expedicion = {
                id_expedicion: ruta._id,
                cantidad: 1,
                titulo: pro.titulo,
                codigo: pro.codigo,
                producto: pro
            }
            const producto_nuevo = await actualizarProducto(producto_expedicion)
            if(!producto_nuevo) return toast.error("Error al actualizar el producto")
            setProductos(prev => {
                let actual = [...prev]
                actual.unshift(producto_nuevo)
                return [...[], ...actual]
            })
        }
    }
    const consultarProducto = async (value) => {
        setLoadingBusqueda(true)
        return fetch(`${data.urlapi}/productos/search-code`,{
            method:'POST',
            body: JSON.stringify({
                codigo_busqueda: value,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res)){
                if(res.length < 1) return toast.error("Sin datos")
                let pro = {...res[0]}

                agregarProducto(pro)

                
            }
            setLoadingBusqueda(false)
            limpiarInput()
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingBusqueda(false)
            limpiarInput()
        })
    }

    const solicitarCambioFecha = (id) => {
        return confirmAlert({
            title: `¿Cambio de fechas?`,
            message: `Actualizar el rango de entrega modificará individualmente cada orden siempre y cuando éstas no estén finalizadas.`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarCambioFecha()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const mostrarBotonActualizarFecha = () => {
        if(!showButtonUpdateDate) return false
        return <Button size="sm" className='w-100' onClick={()=>solicitarCambioFecha()} >ACTUALIZAR RANGO DE ENTREGA</Button>
    }

    const confirmarEliminadoRuta = async () => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/expediciones?id=${ruta._id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = rutas.expediciones.slug
                }, 1000);
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingRuta(false)
        })
    }

    const solicitarEliminarRuta = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta ruta y todas las órdenes asociadas`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminadoRuta()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const guardarCambios = async () => {
        setGuardando(true)
        return fetch(`${data.urlapi}/expediciones`, {
            method:'PUT',
            body: JSON.stringify(ruta),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
            }
            return setGuardando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setGuardando(false)
        })
    }

    const confirmarExpedicion = async () => {
        const exp = {...ruta}

        if(!exp.id_bodega_origen || !exp.id_bodega_destino){
            return toast.error("Selecciona las bodegas de origen y destino")
        }

        if(exp.id_bodega_origen === exp.id_bodega_destino) return toast.error("Las bodegas de origen y destino no pueden ser iguales")

        exp.confirmed = true
        setGuardando(true)
        return fetch(`${data.urlapi}/expediciones`, {
            method:'PUT',
            body: JSON.stringify(exp),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                window.location.reload()
            }
            return setGuardando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setGuardando(false)
        })
    }

    const handleChangeRuta = e => {
        const { name, value } = e.target
        return setRuta(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }
    
    const onSelectUsuario = e => {

        return setRuta(prev => {
            let actual = {...prev}
            actual.usuario = e
            actual.id_usuario = e?._id
            return actual
        })
    }
    
    const onSelectBodega = (e, key, key_id) => {

        return setRuta(prev => {
            let actual = {...prev}
            actual[key] = e
            actual[key_id] = e?._id
            return actual
        })
    }

    const handleSelectModelo = (data, id) => {
        console.log({ data })
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_modelo = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }
    
    const handleSelectMarca = (data, id) => {
        console.log({ data })
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_marca = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleSelectCilindrada = (data, id) => {
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_cilindrada = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleChangeAplicacion = (e) => {
        const { name, value } = e.target
        const idapp = e.target.getAttribute("idapp")
        const pos = e.target.getAttribute("pos")
        if(idapp){
            const i = aplicaciones.findIndex(a => a._id === idapp)
            aplicaciones[i][name] = value
        } else {
            aplicaciones[pos][name] = value
        }
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const solicitarEliminarApp = (id, pos) => {
        if(!id) return eliminarApp(id,pos)
        return confirmAlert({
            title: 'Confirmar',
            message: `Estás a punto de eliminar esta aplicación, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminarApp(id,pos)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    const eliminarApp = async (id,pos) => {
        if(!id) {
            aplicaciones.splice(pos,1)
            return setAplicaciones(prev => [...[], ...aplicaciones])
        }
        setAplicacionId(id)
        return fetch(`${data.urlapi}/productos/aplicaciones?id=${id}`, {
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
                const i = aplicaciones.findIndex(a => a._id === id)
                if(i > -1){
                    aplicaciones.splice(i,1)
                    setAplicaciones(prev => [...[], ...aplicaciones])
                }
            }
            return setAplicacionId(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setAplicacionId(false)
        })
    }

    const agregarAplicacion = () => {
        aplicaciones.unshift({ id_producto: ruta._id })
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleSelectModeloMulti = (data) => {
        setModelosMultiple(data)
    }

    const agregarAplicaciones = () => {
        if(modelosMultiples.length < 1) return toast.error("Selecciona al menos un modelo")
        for( const model of modelosMultiples ){
            aplicaciones.unshift({ id_producto: ruta._id, id_modelo: model.value })
        }
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }
    
    
    const mostrarProductos = () => {
        if(!productos) return false
        if(productos.length < 1) return false

        return <div className='mt-3'>
            <h4><LuBox /> {productos.length} Productos</h4>
            {
                productos.map((pro,ip) => {
                    return <div key={`pro-${ip}`} className="mb-3">
                        <Card className='p-4'>
                            <Row>
                                <Col md={4}>
                                    <p className='mb-0'>Producto</p>
                                    <h5>{pro.titulo}</h5>
                                    <Button size='sm' variant='outline-danger' disabled={loadingEliminar} onClick={()=>eliminarProductoExpedicion(pro._id)} >ELIMINAR</Button>
                                </Col>
                                <Col md={4}>
                                    <p className='mb-0'>Código</p>
                                    <h3>{pro.codigo}</h3>
                                </Col>
                                <Col md={2}>
                                    <p className='mb-0'>Cantidad pedida</p>
                                    <input disabled={disableInputs} type="number" className='form-control' name="cantidad" value={pro.cantidad} onChange={(e) => handleChangeProducto(e,ip)} />
                                </Col>
                                <Col md={2}>
                                    <p className='mb-0'>Cantidad pickeada 
                                    { parseInt(pro.cantidad_pickeada) === parseInt(pro.cantidad) ? <FaCheckCircle color='green' /> : false }
                                    { parseInt(pro.cantidad_pickeada) > parseInt(pro.cantidad) ? <IoAlertCircleSharp color='orange' size={20} /> : false }
                                    </p>
                                    <input disabled={disableInputs} type="number" className='form-control' name="cantidad_pickeada" value={pro.cantidad_pickeada} onChange={(e) => handleChangeProducto(e,ip)} />
                                </Col>
                            </Row>
                        </Card>
                        </div>
                })
            }
        </div>
    }

    const onSelectProduct = (data) => {
        agregarProducto(data)
    }

    const render = () => {

        if(loadingRuta === true) return <Spinner animation='border' />
        if(Object.keys(ruta).length < 1) return <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>No hay datos suficientes para visualizar esta sección</h4>
        </Col>
        </Row>
        const i = conductores.findIndex(c => c.value === ruta.id_conductor)
        let default_vehiculo = false
        if(i > -1) default_vehiculo = conductores[i]
    
        return <div>
        <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to={rutas.expediciones.slug}>Expediciones</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Detalles</Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        
    <Row>
        <Col md={12}>
            <Row>
            <Col md={6}>
                <h4><FaRegFileAlt /> Expedicion {ruta?.id}</h4>
            </Col>
            <Col md={6} className="text-right"><Button size="sm" variant="link" className='p-0 text-danger' onClick={()=>solicitarEliminarRuta()}>ELIMINAR</Button></Col>
            <Col><p className='mb-1'><b>CREADO: </b>{fechaATexto(ruta.createdAt)}</p></Col>
            <Col><p className='mb-1'><b>POR: </b>{ruta.creador?.nombres} · {ruta.creador?.email}</p></Col>
            <Col><p className='mb-1' style={{ textTransform: "uppercase" }}><b>USUARIO ASIGNADO</b> {ruta.usuario?.nombres} {ruta.usuario?.apellidos}</p></Col>
            <Col><p className='mb-1' style={{ textTransform: "uppercase" }}><b>BODEGA DESTINO</b> {ruta.bodega_destino?.titulo}</p></Col>
            </Row>
        </Col>
        {/* <Col md={12}>
            <label className='form-control-label d-block'>Notas</label>
            <textarea style={{ minHeight: 150 }} placeholder='Escribe notas del producto' className='form-control mb-3' name="notas" defaultValue={ruta.notas} onChange={handleChangeProducto} ></textarea>
        </Col> */}
        {/* <Col md={12} className="mb-3">
            { loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={()=>actualizarProducto()}>GUARDAR CAMBIOS</Button> }
        </Col> */}
    </Row>
    <Accordion className='mb-3'>
    <Card className="p-0" >
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={"estado._id"}>
                            <div className="pl-3 pr-3 pb-0"><h6 className='mb-0 hover'><FaAngleDown /> MÁS INFORMACIÓN</h6></div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"estado._id"}>
                            <Card.Body style={{ backgroundColor: '#f1f1f1' }}>
                                <Row>
                                    <Col>
                                    <h6>Usuario asignado</h6>
                                    <ModalSeleccionUsuarios titulo={`${ruta.usuario?.nombres} ${ruta.usuario?.apellidos}`} onSelect={(data) => onSelectUsuario(data)} /></Col>
                                    <Col>
                                    <h6>Bodega origen asignada</h6>
                                    <ModalSeleccionBodega titulo={ruta.bodega_origen?.titulo} onSelect={(data) => onSelectBodega(data,'bodega_origen', 'id_bodega_origen')} /></Col>
                                    <Col>
                                    <h6>Bodega destino asignada</h6>
                                    <ModalSeleccionBodega titulo={ruta.bodega_destino?.titulo} onSelect={(data) => onSelectBodega(data,'bodega_destino', 'id_bodega_destino')} /></Col>
                                </Row>
                            
                            
                            <ButtonGroup>
                                <Button size='sm' variant='outline-primary' disabled={loadingClonado} onClick={()=> clonar("salida")}>CLONAR EN SALIDA</Button>
                                <Button size='sm' variant='outline-primary' disabled={loadingClonado} onClick={()=> clonar("ingreso")}>CLONAR EN INGRESO</Button>
                            </ButtonGroup>

                            <label className='form-control-label d-block'>Notas</label>
                            <textarea type="date" className='form-control mb-3' style={{ minHeight: 100 }} name="notas" defaultValue={ruta.notas} onChange={handleChangeRuta} />
                            <Button size='sm' variant='success' className='mr-3' disabled={guardando || ruta.confirmed} onClick={()=> guardarCambios()}>GUARDAR CAMBIOS</Button>
                            <Button size='sm' variant='outline-success' className='mr-3' disabled={guardando || ruta.confirmed} onClick={()=> confirmarExpedicion()}>CONFIRMAR Y CERRAR EXPEDICIÓN</Button>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
    </Accordion>
    {
        ruta.confirmed === true ? false : <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
            <CiBarcode style={{ margin: "0 auto" }} size={40} />
            <h4 className='d-block'>Escanea el código del producto  </h4>
            <ModalSeleccionProductos onSelect={(data) => agregarProducto(data)} />
            <input id="codigobusqueda" disabled={loadingBusqueda} placeholder='Pega aquí el código y presiona ENTER o utiliza un escanner' className='form-control' name="codigo_fabrica" autoFocus defaultValue={ruta.codigo_fabrica} onChange={handleChangeCodigo} />
            </Col>
        </Row>
    }
    
        {mostrarProductos()}
</div>
    }

    return <Structure component={render()} />

}

export default EditarExpedicion