import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import Usuarios from "../../components/Usuarios"
import UsuariosListado from "../../components/Usuarios/listado"
import { FaRegUser } from "react-icons/fa6"

const ModalSeleccionUsuarios = (props) => {
    const {
        titulo
    } = props
    const title = titulo ? titulo : "SELECCIONAR USUARIO"
    const [ openModal, setOpenModal ] = useState(false)

    const handleClose = () => {
        setOpenModal(false)
    }

    const onSelect = (data) => {
        if(props.onSelect)  props.onSelect(data)
        handleClose()
    }

    const modalFiltro = () => {

        return <Modal show={openModal} size="lg" onHide={()=>handleClose()} centered >
            <Modal.Header closeButton>
            <Modal.Title>Filtrar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UsuariosListado typeView="function" onSelect={(data) => onSelect(data)} />
            </Modal.Body>
      </Modal>
    }

    return <div>
        <Button size="sm" variant="light" className="mb-3" onClick={() => setOpenModal(true)}><FaRegUser /> {title}</Button>
        {modalFiltro()}
    </div>
}

export default ModalSeleccionUsuarios