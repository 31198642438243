const acciones = [
    { slug:'ver', label: "Ver" },
    { slug:'crear', label: "Crear" },
    { slug:'modificar', label: "Modificar" },
    { slug:'eliminar', label: "Eliminar" },
]

const logotipo = window.location.protocol + "//" + window.location.host + "/logo-mesina.png"
const logotipo_2 = window.location.protocol + "//" + window.location.host + "/images/logo-mesina.png"
const url_images = window.location.protocol + "//" + window.location.host + "/images"

module.exports = {
    acciones,
    logotipo,
    logotipo_2,
    url_images
}