import React, { Component} from 'react'
import { connect } from 'react-redux'
import { key_local_storage_user } from '../../lib/backend/data'
import 'moment/locale/es';
import { cerrarSesion } from '../../redux/actions/session'
import ProductosImportador from '../../subComponents/productos/importador/importador'
import Structure from '../Structure';

class ProductosImportar extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user))
        }
    }

    datos() {
        const { user } = this.state
        return <div>
                <ProductosImportador token={user.tokenSession} />
        </div>
    }

    render(){
        return <Structure component={this.datos()} />
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductosImportar);