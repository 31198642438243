import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Table, Tabs, Tab } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';
import ProductosListado from './listado'
import { checkPermission } from '../../lib/helpers/session'
import { rutas } from '../../lib/rutas'
import { FaPlus, FaRegFileExcel } from 'react-icons/fa6'

class Productos extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadingUsuarios: true,
            loadingMore: false,
            total: 0,
            usuarios: [],
            parametro_busqueda: '',
            loadingBusqueda: false,
            filtro_rol: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.buscarUsuario = this.buscarUsuario.bind(this)

    }

    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    componentDidMount(){
        const { user } = this.state
        const permitir = checkPermission('productos', user)
        if(permitir !== true) window.location = '/'
    }

    buscar(){
        const { parametro_busqueda, user } = this.state
        if(!parametro_busqueda) return toast.error('El valor de búsqueda es requerido')
        if(parametro_busqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido')
        let loading = 'loadingBusqueda'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/search`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
            body: JSON.stringify({ parametro_busqueda })
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.length < 1){
                toast.warn('No se encontraron resultados')
                return this.setState({ [loading]: false })
            }
            return this.setState({ usuarios: res, filtro_rol:'', [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async getUsuarios(cargarmas){
        const { user, usuarios } = this.state
        let loading = 'loadingUsuarios'
        if(cargarmas===true) loading = 'loadingMore'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/list?skip=${usuarios.length}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(cargarmas===true){
                if(Array.isArray(res.datos) !== false){
                    const final_array = usuarios.concat(res.datos)
                    return this.setState({ usuarios: final_array, total: res.total, [loading]: false })
                } else {
                    return this.setState({ [loading]: false })
                }
            } else {
                if(Array.isArray(res.datos) !== false){
                    return this.setState({ usuarios: res.datos, total: res.total, [loading]: false })
                } else {
                    return this.setState({ [loading]: false })
                }
            }
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    buscarUsuario(e){
        e.preventDefault()
        return this.buscar()
    }
    
    sinRegistros(){
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                <h4>Aún no tienes usuarios</h4>
                <p>Puedes crear diversos usuarios y asignar permisos a cada uno de ellos</p>
                <Link to="/productos/nuevo"><Button size="sm" variant="primary">CREAR NUEVO</Button></Link>
                </Col>
            </Row>
        </div>
    }

    mostrarUsuarios(){
        const { usuarios, loadingUsuarios, loadingMore, total, filtro_rol, loadingBusqueda } = this.state
        if(loadingUsuarios) return <Spinner animation='border' />
        if(usuarios.length < 1) return this.sinRegistros()

        const filtrados_rol = filtro_rol ? usuarios.filter(user => user.role === filtro_rol) : usuarios
        return <div>

            <form onSubmit={this.buscarUsuario}>
            <Row className='mb-3'>
                <Col md={2} className='mb-3'>
                    <label className='form-control-label d-block'>Buscar</label>
                    <input className='form-control' name="parametro_busqueda" onChange={this.handleChange} />
                </Col>
                <Col md={2} className='mb-3'>
                    <label className='form-control-label d-block'>Click para buscar</label>
                    {
                        loadingBusqueda === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={()=>this.buscar()}>BUSCAR</Button>
                    }
                </Col>
                <Col md={2}>
                    <label className='form-control-label d-block'>Filtrar por rol</label>
                    <select className='form-control' name="filtro_rol" value={filtro_rol} onChange={this.handleChange} >
                        <option value="" >Seleccione</option>
                        <option value="user" >Usuario</option>
                        <option value="admin" >Administrador</option>
                    </select>
                </Col>
            </Row>
            </form>
            
            <Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="activos" title="Activas">
                <h5 className='mb-3'>Se muestran {filtrados_rol.length} de {total} registros</h5>
                <div className='table-responsive'>
                <Table bordered className='table'>
                    <thead>
                        <tr>
                            <th>Nombres</th>
                            <th>Apellidos</th>
                            <th>Email</th>
                            <th>Teléfono</th>
                            <th>Estado</th>
                            <th>Creación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtrados_rol.map((usuario,i) => {
                                return <tr key={`user-${usuario._id}`}>
                                        <th><Link to={`/usuarios/edit-${usuario._id}`}>{usuario.nombres}</Link> </th>
                                        <th>{usuario.apellidos}</th>
                                        <th>{usuario.email}</th>
                                        <th>{usuario.phone}</th>
                                        <th>{usuario.status === "active" ? 'Activo' : 'Inactivo'}</th>
                                        <th><Moment locale="ES" fromNow>{usuario.createdAt}</Moment></th>
                                    </tr>
                            })
                        }
                    </tbody>

                </Table>
                {
                    usuarios.length < total ? <div>
                        {
                            loadingMore === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={()=>this.getUsuarios(true)}>CARGAR MÁS</Button>
                        }
                    </div> : false
                    
                }
                
                </div>
            </Tab>
            <Tab eventKey="archivados" title="Archivados">

            </Tab>
            </Tabs>
            
            
        </div>
    }
    
    render(){
        const { user } = this.state
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pb-2 mb-3">
      </div>
    <h4>Productos </h4>
    <Link to="/productos/nuevo"><Button size="sm" className="mb-3 mr-3" variant="light"><FaPlus /> CREAR NUEVO</Button></Link>
    <Link to={`${rutas.importador_productos.slug}`}><Button size="sm" className="mb-3" variant="light"><FaRegFileExcel /> IMPORTAR</Button></Link>
      <Card >
          <Card.Body>
          <Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="activos" title="Activos">
                <ProductosListado token={user.tokenSession} condicion_default={{ status: { $nin: ["trash"] } }} />
            </Tab>
            <Tab eventKey="archivadas" title="Archivadas">
                <ProductosListado token={user.tokenSession} trash={true} condicion_default={{ status: 'trash' }} />
            </Tab>
        </Tabs>
          </Card.Body>
      </Card>
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Productos);