const debug = false

const url_images = window.location.protocol + "//" + window.location.host + "/images"

const datos = {
    urlapi: debug === true ? 'http://localhost:5004/api/1.0' : 'https://backend.wesyncro.click/api/1.0',
    key_local_storage_user: 'mz_bknd_user',
    key_local_storage_cart: 'mz_bknd_cart',
    key_local_client: 'mz_bknd_client',
    key_local_storage_pedidos: 'mz_bknd_pedidos',
    pagina: 20,
    iva_global: 1.19,
    url_images,
    configs3: {
        dirName: "agf/",
        bucketName: "testing-agf",
        region: "us-west-2",
        accessKeyId: 'AKIAYXYN322Y2VEKQMX5',
        secretAccessKey: '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',
    },
}

module.exports = datos