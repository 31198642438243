import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa6';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// Configura el worker de PDF.js requerido por react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [ loading, setLoading ] = useState(true)
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    setLoading(true)
  }, [])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };


  return (
    <div className="pdfViewer"> {/* Aplica la clase aquí */}
      <Button disabled={loading} className='mb-3' onClick={handlePrint}><FaPrint /> Imprimir PDF</Button>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} >
        {/* <Page pageNumber={1} className="customPageSize" scale={2} /> */}
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5} />
          ),
        )}
      </Document>
    </div>
  );
};

export default PdfViewer;
